import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from 'components/seo'
import { Container, Row, Col } from "react-bootstrap";
import StaticBackgroundImage from "../components/StaticBackgroundImage";
const BaseTemplate = ({
  data, pageContext // this prop will be injected by the GraphQL query below.
}) => {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body, excerpt } = mdx;
  return (
    <>
      <SEO title={frontmatter.title} description={excerpt}/>
      <section id="main">
        <StaticBackgroundImage image={frontmatter.background} hasDefault>
          <Container>
            <Row>
              <Col lg={10} className="mx-auto">
                <h1 id="title">{frontmatter.title}</h1>
                <MDXRenderer className="pages">
                  {body}
                </MDXRenderer>
              </Col>
            </Row>
          </Container>
        </StaticBackgroundImage>
      </section>
    </>
  )
};

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        background: image{
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, breakpoints: [1024, 1280, 1440, 2500])
          }
        }
      }
    }
  }
`;

export default BaseTemplate;